.block--steps {
    .common-title--lg {
        margin: 0 auto 100px auto;
        max-width: none;
        display: block;
        text-align: center;
        &::after {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .single-step {
        clear: both;
        position: relative;
        text-align: center;
        .num-box {
            margin-top: 70px;
            margin-bottom: 50px;
            width: 410px;
            height: 210px;
            background-color: $brand-gray-lite;
            padding: 40px 60px;
            line-height: 1.3;
            margin-right: 90px;
            position: relative;
            display: inline-block;
            .num-box--text {
                font-size: 32px;
                font-style: italic;
                text-align: left;
            }
            .num-box--num {
                font-size: 200px;
                font-weight: 700;
                position: absolute;
                top: -75px;
                right: -35px;
                color: $brand-black;
                transition: color 0.3s ease-in-out;
            }
            &.active .num-box--num {
                color: $brand-yellow;
            }
        }
        .title-box {
            margin-top: 70px;
            margin-bottom: 50px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            text-align: left;
            span {
                font-size: 40px;
                font-weight: 700;
                text-transform: uppercase;
                width: 350px;
                display: inline-block;
            }
            .title-box--num {
                display: none;
                transition: color 0.3s ease-in-out;
            }
            &.active .title-box--num {
                color: $brand-yellow;
            }
        }
        .arrow-down {
            position: absolute;
            left: 0;
            bottom: -80px;
            transition: all 0.3s ease-in-out;
            z-index: 998;
            outline: 0;
            &.active {
                transform: rotate(180deg);

            }
        }
        .hidden-step {
            text-align: left;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .single-step:first-child {
        .num-box,
        .text-box {
            margin-top: 0;
        }
    }
    .button-white {
        margin-top: 150px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}
.block--steps {
    .single-step {
        .num-box {
            padding: 25px 38px;
            width: 265px;
            height: 135px;
            .num-box--text {
                font-size: 20px;
            }
            .num-box--num {
                font-size: 128px;
            }
        }
        .title-box span {
            font-size: 25px;
            width: 250px;
        }
        .arrow-down {
            bottom: -50px;
            width: 50px;
            img {
                width: 100%;
            }
        }
        .hidden-step {
            max-width: 600px;
        }
    }
}
