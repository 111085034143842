// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$brand-yellow: #ffdc15;
$brand-yellow-tr: rgba(255, 220, 21, 0.8);
$brand-black: rgba(29, 29, 27, 1);
$brand-black-tr: rgba(29, 29, 27, 0.9);
$brand-gray: #242424;
$brand-gray-lite: #F7F7F7;
$brand-white: #FFF;
$brand-font: 'Roboto', sans-serif;
$brand-font-text: 'Roboto', sans-serif;
