.button-common,
.gform_button {
    color: $brand-black;
    padding: 12px 15px;
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    opacity: 1;
}
.button-common:hover {
    color: $brand-black;
    text-decoration: none;
}
.button-yellow,
.gform_button {
    background-color: $brand-yellow;
    border: 2px solid $brand-yellow;
    cursor: pointer;
}
.button-white {
    border: 2px solid $brand-black;
    cursor: pointer;
}
.button-yellow-border {
    border: 2px solid $brand-yellow;
    cursor: pointer;
}
.button-yellow:hover,
.gform_button:hover,
.button-white:hover {
    opacity: 0.8;
}
.banner-button {
    padding: 16px 22px;
    font-size: 24px;
}
.banner-button--video {
    color: $brand-white;
    font-size: 28px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    img {
        margin: -3px 15px 0 25px;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }
}
.banner-button--video:hover {
    color: $brand-white;
    text-decoration: none;
    img {
        opacity: 0.8;
    }
}
