// Two columns
.block--two-columns {
    padding: 100px 0;
    .button-common {
        margin-top: 15px;
    }
    .image-column {
        width: 100%;
        padding-right: 50px;
        img {
            width: 100%;
        }
    }
    .text-column {
        max-width: 690px;
        h1 {
            margin-bottom: 25px;
        }
    }
    .button-row {
        a:first-child {
            margin-right: 15px;
        }
    }
}

// Three columns
.block--three-columns {
    padding: 100px 0;
    .common-title--lg {
        max-width: 280px;
    }
    .text-column {
        padding-right: 30px;
        ul {
            margin-bottom: 0;
            li {
                position: relative;
                padding-left: 40px;
                margin-bottom: 30px;
                &::before {
                    background-image: url("../images/svg/check.png");
                    position: absolute;
                    left: 0;
                    top: 5px;
                    content: "";
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    background-size: 90%;
                    background-repeat: no-repeat;
                }
            }
        }
        &.no-padding {
            ul {
                li {
                    margin-bottom: 5px;
                }
            }
        }
        .button-common {
            margin-top: 35px;
        }
    }
}
.bottom-padding-sm {
    padding-bottom: 80px;
}
// Two columns image
.block--two-columns-image {
    .image-column {
        img {
            width: 100%;
        }
    }
    .text-column {
        .common-title--lg {
            max-width: 380px;
        }
        .gray-box {
            padding: 80px;
            background-color: $brand-gray-lite;
            p {
                margin-bottom: 30px;
            }
            .button-white {
                margin-top: 30px;
            }
        }
    }
}
.block--border {
    .border-gray {
        height: 1px;
        background-color: rgba(0,0,0,0.15);
    }
}
