// Block Quotes - Home
.block--home-quotes {
    .common-title--lg {
        padding-left: 15px;
    }
}
.quote-wrap {
    background: url("../images/quotes.png");
    background-size: cover;
    background-position: center;
    height: 480px;
    width: 100%;
    color: $brand-white;
    padding: 55px 0;
    position: relative;
    overflow: hidden;
    .single-quote {
        display: block;
        position: absolute;
        width: 50%;
        top: 100px;
        left: 100%;
        opacity: 0;
        transition: left 0.3s ease-in-out, opacity 0.2s ease-in-out;
        &.active {
            left: 15px;
            opacity: 1;
        }
        &.temp {
            left: -100%;
            opacity: 0;
        }
        p {
            font-size: 20px;
            font-weight: 300;
            font-style: italic;
        }
        .quote-author {
            margin-top: 40px;
            span {
                font-size: 20px;
                display: block;
                line-height: 1.3;
            }
        }
    }
}
.buttons-wrap {
    position: relative;
    height: 100%;
}
.slider-buttons {
    position: absolute;
    bottom: 93px;
    left: 50px;
    .slider-button {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 7px;
        margin-top: 70px;
        cursor: pointer;
        .button-line {
            width: 100%;
            height: 4px;
            margin-top: 13px;
            background-color: $brand-white;
        }
    }
    .slider-button.active {
        .button-line {
            background-color: $brand-yellow;
        }
    }
}
