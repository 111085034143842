// Home - banner
.home-banner {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    .container {
        position: relative;
        height: 100%;
    }
    .home-banner--text {
        position: absolute;
        top: 28%;
        left: 0;
        padding-left: 50px;
        span {
            font-size: 64px;
            font-weight: 700;
            max-width: 680px;
            display: block;
            line-height: 1.1;
            margin-bottom: 40px;
        }
    }
    .home-banner--bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        .home-banner--bottom-box {
            width: 33%;
            height: 200px;
            background-color: $brand-white;
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
            a {
                color: $brand-black;
                height: 100%;
                width: 100%;
                display: block;
                &:hover {
                    text-decoration: none;
                }
                &::before {
                    content: "";
                    height: 20px;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $brand-white;
                    transition: all 0.3s ease-in-out;
                    z-index: -1;
                }
                &:hover:before {
                    content: "";
                    height: 20px;
                    width: 100%;
                    position: absolute;
                    top: -20px;
                    left: 0;
                    background-color: $brand-yellow;
                }
                &::after {
                    content: "";
                    height: 20px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: $brand-white;
                    transition: all 0.3s ease-in-out;
                    z-index: -1;
                }
                &:hover:after {
                    content: "";
                    height: 20px;
                    width: 100%;
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    background-color: $brand-yellow;
                }
            }
            .box-image {
                width: 150px;
                height: 150px;
                overflow: hidden;
                margin: 25px 20px 25px 25px;
                display: inline-block;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .box-text {
                display: inline-block;
                width: 50%;
                vertical-align: top;
                padding-top: 25px;
                .box-text--title {
                    display: block;
                    line-height: 1.2;
                }
                p {
                    margin: 15px 0 0;
                    line-height: 1.3;
                }
            }
            .box-arrow {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                polygon {
                    transition: all 0.3s ease-in-out;
                    fill: $brand-yellow;
                }
            }
        }
        .home-banner--bottom-box:hover {
            background-color: $brand-yellow;
            z-index: 10;
            .box-arrow polygon {
                fill: $brand-white;
            }
        }
    }
}

// Sub page - Banner
.page-banner {
    height: 615px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .container {
        position: relative;
        top: 60%;
        h1 {
            font-size: 86px;
            font-weight: 700;
        }
    }
}
