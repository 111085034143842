// Block Home-info
.block--home-info {
    padding: 170px 0 100px 0;
    .common-title--lg.desktop {
        margin-left: 115px;
    }
    .common-title--lg.mobile {
        display: none;
    }
    .common-title--md {
        &::after,
        &::before {
            background-color: transparent;
        }
    }
}
.image-text-overflow {
    margin-top: 65px;
    max-width: 650px;
    position: relative;
    img {
        width: 100%;
        height: auto;
    }
    .box-overflow {
        padding: 50px;
        background-color: $brand-gray-lite;
        max-width: 460px;
        position: absolute;
        bottom: -65px;
        right: calc(-100% + 240px);
        .button-common {
            margin-top: 25px;
        }
    }
    .box-overflow--long {
        bottom: auto;
        top: 65px;
        a {
            color: $brand-black;
            padding: 12px 15px;
            display: inline-block;
            text-decoration: none;
            transition: background-color 0.2s ease-in-out;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 18px;
            line-height: 1;
            border: 2px solid $brand-black;
            margin: 20px 0;
        }
        p:last-child {
            margin-bottom: 0;
            a {
                margin-bottom: 0;
            }
        }
    }
}
.home-info--right {
    img {
        width: 100%;
        height: auto;
    }
    p {
        margin: 30px 0;
        padding-right: 30px;
    }
}

// Block image-text overflow
.block--image-text-overflow {
    .common-title--lg {
        margin-left: 700px;
    }
    .image-text-overflow {
        margin-top: 0;
        .box-overflow {
            max-width: 660px;
            right: calc(-100% + 40px);
            padding: 100px;
        }
    }
}
