// Column - icons
.block--info-icons {
    background-position: none;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 75px 0;
    .common-title--md {
        max-width: 450px;
    }
    .button-common {
        margin-top: 20px;
    }
    .button-white {
        margin-left: 15px;
    }
    .single-info-icon--wrap {
        max-width: 870px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        padding-left: 15px;
        p {
            margin-bottom: 30px;
        }
    }
    .single-info-icon {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-bottom: 50px;
        max-width: 380px;
        margin-right: 35px;
        span {
            display: block;
            padding-left: 90px;
            line-height: 1.5;
        }
        img {
            position: absolute;
            left: 0;
            top: 5px;
        }
    }
    .mt-lg {
        margin-top: 75px;
    }
    .mb-sm {
        margin-bottom: 25px;
    }
    .w-sm {
        max-width: 720px;
    }
}

.gravity-form {
    width: 760px;
    position: relative;
    .gfield_label {
        display: none !important;
    }
    .input-gravity {
        max-width: 380px !important;
        margin: 0 0 50px 0 !important;
        input {
            width: calc(100% - 30px) !important;
            height: 50px;
            line-height: 50px;
            padding-left: 15px !important;
            outline: 0;
            border: 1px solid #cfd0d3;
        }
    }
    .input-gravity:last-child {
        margin: 0 !important;
    }
    .textarea-gravity {
        max-width: 380px;
        position: absolute;
        right: 0;
        top: 0;
        .ginput_container_textarea {
            margin-top: 0 !important;
            outline: 0;
            textarea {
                border: 1px solid #cfd0d3;
                height: 350px;
                padding: 15px !important;
            }
        }
    }
}
