header.header {
    background-color: $brand-yellow-tr;
    height: 135px;
    width: 100%;
    position: fixed;
    z-index: 998;
    top: 0;
    transition: top 0.3s ease-in-out;
    overflow: hidden;
    .container {
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        padding: 0;
        overflow: hidden;
        top: 0;
        transition: top 0.3s ease-in-out;
        position: absolute;
    }
    .brand {
        position: absolute;
        left: 35px;
        bottom: 25px;
        img {
            width: 300px;
        }
    }
    nav {
        a {
            color: $brand-black;
            transition: color 0.2s ease-in-out;
            font-size: 17px;
            position: relative;
            &::after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                background-color: transparent;
                left: 50%;
                bottom: -24px;
                transition: all 0.3s ease-in-out;
                display: block;
            }
        }
        a:hover {
            text-decoration: none;
        }
    }
    .nav-primary {
        height: 100%;
        width: 60%;
        float: right;
        position: relative;
        ul {
            position: absolute;
            bottom: 20px;
            right: 35px;
            li {
                margin-right: 30px;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 20px;
            }
            li:last-child {
                margin-right: 0;
            }
            li.active a,
            li:hover a {
                &::after {
                    background-color: $brand-black;
                    height: 5px;
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
    .nav-secondary {
        position: absolute;
        right: 35px;
        top: 20px;
        li {
            display: inline-block;
            margin-right: 20px;
        }
        li:last-child {
            margin-right: 0;
        }
        li.active a {
            &::after {
                background-color: transparent;
            }
        }
    }
    .mobile-menu {
        position: absolute;
        right: 30px;
        top: 15px;
        display: none;
        cursor: pointer;
        width: 35px;
        height: 35px;
        img {
            width: 100%;
            position: absolute;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        img.active {
            opacity: 1;
        }
    }
    &.nav-up {
        top: -135px;
    }
}
.solid-yellow {
    width: 30%;
    height: 100%;
    background-color: $brand-yellow;
    position: absolute;
    top: 0;
    left: 0;
}
 #triangle-bottomright {
     width: 0;
     height: 0;
     border-top: 136px solid $brand-yellow;
     border-right: 50px solid transparent;
     position: absolute;
     right: -50px;
     top: 0;

 }
.mobile-nav {
    width: 100%;
    right: 0;
    top: 75px;
    max-height: 0;
    z-index: 10;
    position: fixed;
    background-color: $brand-yellow;
    display: none;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    z-index: 988;
    .nav {
        display: block;
        padding: 35px 20px;
        a {
            color: $brand-black;
            transition: color 0.2s ease-in-out;
        }
        a:hover {
            text-decoration: none;
        }
        li {
            display: block;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 12px;
        }
        li:last-child {
            margin-bottom: 0;
        }
        li.active a {

        }
    }
    &.active {
        max-height: 275px;
    }
}
