//Block Logos - Home
.block--logos {
    .logo-box {
        min-height: 230px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        max-width: 1366px;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 1px solid #e5e5e5;
        img {
            align-self: center;
            max-width: 200px;
            max-height: 80px;
        }
    }
}
