// Location
.block--location {
    background-color: $brand-gray-lite;
    .common-title--lg {
        max-width: 220px;
    }
    .location-text {
        padding: 80px 15px;
    }
    .location-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        a {
            color: $brand-black;
        }
    }
    .location-content {
        margin-top: 30px;
        p {
            font-size: 16px;
            margin-bottom: 55px;
        }
    }
    .google-map {
       width: 100%;
       height: 100%;
       background-color: $brand-gray-lite;
    }
}

// Newsletter
.footer-newsletter {
    width: 100%;
    overflow: hidden;
    height: 150px;
    background-color: $brand-yellow-tr;
    position: relative;
    .col-sm-6 {
        position: relative;
        height: 100%;
    }
    .container,
    .row,
    .col-sm-6 {
        height: 100%;
    }

    p,
    form {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
    }
    p {
        font-size: 32px;
    }
    form {
        input {
            font-size: 22px;
            line-height: 36px;
            padding: 5px 20px;
            border: none;
            margin-right: 15px;
            &::placeholder {
                font-style: italic;
            }
        }
        a {
            vertical-align: top;
        }
    }
    .pfb-signup-button {
        background: transparent;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
    }
    .mobile-newsletter  {
        display: none;
    }
    .newsletter-left {
        text-align: right;
        background-color: #FDE47D;
        padding-right: 40px;
        position: relative;
    }
    .newsletter-right {
        text-align: left;
        background-color: #FCDC5A;
        padding-left: 70px;
    }
}
.solid-yellow-news {
    position: absolute;
    height: 150px;
    width: 45%;
    left: 0;
    top: 0;
    background-color: $brand-yellow;
}
#triangle-right {
    width: 0;
    height: 0;
    border-top: 136px solid $brand-yellow;
    border-right: 50px solid transparent;
    position: absolute;
    right: -50px;
    top: 0;
}

// Footer
.footer {
    padding: 80px 0;
    color: $brand-white;
    background-color: $brand-gray;
    .footer-logo {
        width: 115px;
        margin-bottom: 35px;
    }
    p,
    a {
        font-size: 16px;
    }
    p {
        margin-bottom: 0;
        padding-right: 25px;
        max-width: 225px;
    }
    .container {
        .column {
            .footer-title {
                color: $brand-yellow;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 700;
            }
            .footer-links {
                margin-top: 45px;
                li {
                    margin-bottom: 5px;
                }
                a {
                    color: $brand-white;
                    text-transform: uppercase;
                    &:hover {
                        color: $brand-white;
                    }
                }
            }
            .footer-contacts {
                margin-top: 45px;
                li {
                    padding-left: 35px;
                    margin-bottom: 20px;
                    position: relative;
                    a {
                        color: $brand-white;
                        &:hover {
                            color: $brand-white;
                        }
                    }
                }
                .social-logos {
                    padding-left: 0;
                }
                .contact-logo {
                    height: 20px;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
                .contact-logo--arrow {
                    height: 30px;
                    left: -3px;
                    top: 0;
                }
                .social-logos a {
                    margin-right: 20px;
                    display: inline-block;
                    img {
                        height: 20px;
                    }
                }
            }
        }
    }
}
.sub-footer {
    height: 90px;
    text-align: center;
    background-color: #131313;
    p {
        font-size: 14px;
        color: $brand-white;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        span {
            font-size: 14px;
        }
        a {
            color: $brand-yellow;
        }
    }
}
