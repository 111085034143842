// Block Widget
.block--widget {
    .padding-b {
        margin-bottom: 50px;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
    }
    .widget-left {
        max-width: 660px;
        padding-left: 15px;
    }
    .widget-right {
        max-width: 430px;
        padding-right: 15px;
    }
    .common-title--lg {
        max-width: 555px;
    }
    .common-title--md {
        margin-top: 35px;
    }
    .widget-item {
        position: relative;
        border-bottom: 1px solid #d9d9d9;
        margin-top: 60px;
        padding-bottom: 50px;
        .widget-item--date {
            width: 100px;
            height: 100px;
            background-color: $brand-gray-lite;
            text-align: center;
            position: absolute;
            left: 0;
            span {
                line-height: 1;
            }
            .date-day {
                display: block;
                font-size: 56px;
                letter-spacing: -5px;
                padding-top: 7px;
                padding-right: 5px;
            }
            .date-month {
                color: #a5a5a5;
                display: block;
                font-size: 24px;
                text-transform: uppercase;
            }
        }
        .widget-item--content {
            padding-left: 125px;
            line-height: 1.2;
            p {
                margin: 15px 0 20px 0;
            }
        }
    }
}
