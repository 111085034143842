// Block Events
.block--events {
    padding: 100px 0;
    h1 {
        padding-left: 15px;
        margin-bottom: 55px;
    }
    .container {
        .common-title--lg {
            margin: 0 auto 100px auto;
            max-width: none;
            display: block;
            text-align: center;
            &::after {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .common-link {
        margin-left: 30px;
        margin-top: 35px;
        display: inline-block;
    }
}
.item-post {
    text-align: left;
    .item-post-image {
        width: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
        }
    }
    .item-post--text-wrap {
        padding: 0 15px;
    }
    .item-post--title {
        margin-top: 8%;
        margin-bottom: 3%;
        line-height: 1.2;
    }
    .item-post--date {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 3%;
        display: inline-block;
    }
    .item-post--content {
        .moretag {
            display: inline-block;
            margin-top: 45px;
        }
    }
}
