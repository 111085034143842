.block--post-list {
    padding: 100px 0;
    .item-post {
        padding: 0;
        margin-bottom: 100px;
        .item-post-image {
            width: 100%;
            margin-left: 0;
        }
        .item-post--title {
            margin: 7% 0 3% 0;
        }
        .item-post--date {
            margin-bottom: 3%;
            display: inline-block;
        }
    }
    .common-title--md {
        margin-top: 0;
    }
}

.single-post {
    .block--post-list .item-post {
        margin-bottom: 60px;
    }
    .single-share {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 30px;
        span {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
        }
        a {
            display: inline-block;
            margin-left: 20px;
            img {
                max-height: 15px;
            }
        }
    }
    .common-link {
        margin-top: 60px;
        display: inline-block;
    }
}
