html,
body,
.common-title--lg,
.common-title--md {
    font-family: $brand-font;
}
p,
span,
li {
    font-size: 17px;
}
.container:first-child {
    margin-top: 0;
}
.container-sm {
    padding: 0 15px;
}
.yellow-text {
    color: $brand-yellow;
}
.dont-break {
    white-space:nowrap;
}
.z-index-high {
    z-index: 9;
}
.z-index-higher {
    z-index: 12;
}
.common-link {
    text-transform: uppercase;
    text-decoration: underline;
    color: $brand-black;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border-bottom: 1px solid $brand-black;
}
.common-link:hover {
    color: $brand-yellow;
    text-decoration: none;
    border-bottom: 1px solid transparent;
}
h1, h2, h3, h4, h5 {font-weight: 700;}
h1 {
    font-size: 30px;
    display: inline-block;
    margin-bottom: 50px;
    line-height: 1.3;
    text-transform: uppercase;
}
h1::after {
    content: "";
    display: block;
    width: 70%;
    max-width: 200px;
    height: 5px;
    background-color: $brand-yellow;
    margin-top: 10px;
}
.page-header h1::after,
.page-header h1::before {
    background-color: transparent;
}
h2 {
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 50px;
    position: relative;
}
h2::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    bottom: 0px;
    left: 0;
}
h2::after {
    content: "";
    display: block;
    width: 155px;
    height: 2px;
    background-color: $brand-black;
    margin-top: 15px;
}
h3 {
    font-size: 24px;
}
h4 {
    font-size: 20px;
    text-transform: uppercase;
}
h5 {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 40px;
}
h6 {
    font-size: 17px;
    margin-bottom: 40px;
    font-weight: 700;
}
.common-title--lg {
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    line-height: 1.15;
    max-width: 505px;
}
.common-title--lg::after {
    content: "";
    display: block;
    width: 160px;
    height: 9px;
    background-color: $brand-yellow;
    margin-top: 15px;
}
.common-title--md {
    font-size: 36px;
    font-weight: 700;
    display: block;
    line-height: 1.3;
    position: relative;
}
.common-title--md::after {
    content: "";
    display: block;
    width: 155px;
    height: 2px;
    background-color: $brand-black;
    margin-top: 25px;
}
.common-title--md::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.block--section {
    padding: 100px 0;
}
.varovalna-oprema {
    .block--image-text-overflow {
        padding-top: 0;
    }
}
.block--no-padding  {
    padding-top: 0 !important;
}
.hw-md {
    max-width: 400px;
}
.hw-sm {
    max-width: 350px;
}
.hw-xs{
    max-width: 210px;
}
.h-num {
    max-width: 255px;
    position: relative;
    padding-left: 45px;
    &::before {
        content: "1";
        position: absolute;
        left: 0;
        top: 0;
        font-size: 60px;
        line-height: 1;
        color: rgba(179, 179, 179, 0.4);
    }
}
.h-num-2 {
    &::before {
        content: "2";
    }
}
