@media only screen and (min-width: 1900px) {
    .solid-yellow {
        width: 45%;
    }
    .solid-yellow-news {
        width: calc(50% - 100px);
    }
}
@media only screen and (min-width: 1600px) {
    .block--two-columns-image {
        padding-top: 80px;
    }
}
@media only screen and (min-width: 768px) {
    .padding-ld {
        padding-left: 15px;
    }
}
@media only screen and (max-width: 1599px) {
    .container-md {
        padding: 0 8%;
        margin: 0;
        max-width: 100%;
    }
    .padding-lnone,
    .page-banner .container h1 {
        padding-left: 0;
    }
    .block--post-list {
        padding: 100px 0;
    }
    .home-banner {
        .home-banner--text {
            top: 28%;
            .banner-button--video {
                font-size: 26px;
                img {
                    width: 55px;
                }
            }
        }
    }
    .page-banner .container h1 {
        font-size: 68px;
    }
    .block--location {
        .container-sm {
            max-width: 100%;
            .location-text {
                padding: 60px 40px 60px 15px;
                .location-info {
                    display: block;
                }
            }
        }
    }
    .block--image-text-overflow {
        .col-12 {
            padding: 0;
        }
    }
}
@media only screen and (max-width: 1519px) {
    .home-banner {
        .home-banner--bottom .home-banner--bottom-box {
            .box-image {
                margin-right: 15px;
            }
        }
    }
}
@media only screen and (max-width: 1450px) {
    .common-title--lg {
        font-size: 38px;
    }
    .block--home-info {
        padding-top: 100px;
        position: relative;
        .col-lg-9 {
            padding-left: 25px;
            padding-right: 25px;
            .common-title--lg.desktop {
                margin-left: 0;
                position: absolute;
                left: 66%;
                max-width: 400px;
            }
            .image-text-overflow {
                max-width: 65%;
                margin-top: 0;
                position: static;
                .box-overflow {
                    right: 25px;
                    max-width: 36%;
                }
            }
            max-width: 100%;
            flex: 0 0 100%;
        }
        .widget-col {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 135px;
            padding-left: 8%;
            padding-right: 25px;
            .home-info--right {
                .common-title--md {
                    display: inline-block;
                    max-width: 420px;
                    &::after,
                    &::before {
                        content: none;
                    }
                }
                img {
                    width: 50%;
                    float: right;
                }
                p {
                    width: 35%;
                }
                .button-common {
                    width: 210px;
                }
            }
        }
    }
    .quote-wrap .single-quote {
        width: 60%;
    }
    .block--events {
        padding: 70px 0;
        .container {
            .common-title--lg {
                margin-bottom: 60px;
            }
        }
    }
    .block--location {
        .container-sm {
            max-width: 100%;
            .location-text {
                padding: 60px 40px 60px 15px;
            }
        }
    }
    .block--two-columns {
        padding: 100px 0;
        .common-title--lg {
            max-width: 400px;
        }
    }
    .block--two-columns-image {
        .col-md-6:first-child {
            padding-left: 35px;
        }.col-md-6:last-child {
            padding-right: 35px;
        }
    }
    .footer-newsletter {
        height: 130px;
        form input {
            font-size: 18px;
        }
        p {
            font-size: 26px;
            margin-bottom: 0;
        }
    }
    .block--image-text-overflow {
        .common-title--lg {
            margin-left: 60%;
        }
        .image-text-overflow {
            max-width: 55%;
            .box-overflow {
                padding: 70px;
                right: calc(-100% + 16%);
            }
        }
    }
    .block--widget {
        .widget-left {
            max-width: 480px;
        }
        .widget-right {
            max-width: 380px;
            .widget-item {
                margin-top: 48px;
                padding-bottom: 40px;
                .widget-item--content {
                    .content-title {
                        font-size: 18px;
                    }
                    .content-description {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .block--three-columns {
        padding: 100px 0;
        .common-title--lg {
            max-width: 230px;
        }
    }
    .block--section {
        padding: 100px 0;
    }
}
@media only screen and (max-width: 1399px) {
    header.header .nav-primary ul li {
        font-size: 20px;
    }
    .home-banner {
        .home-banner--text {
            top: 32%;
            padding-left: 50px;
            span {
                font-size: 52px;
                max-width: 550px;
            }
            .banner-button {
                padding: 12px 20px;
                font-size: 20px;
            }
            .banner-button--video {
                font-size: 26px;
                img {
                    width: 55px;
                }
            }
        }
        .home-banner--bottom .home-banner--bottom-box {
            height: 180px;
            .box-image {
                width: 120px;
                height: 120px;
                margin-top: 30px;
                margin-left: 25px;
            }
        }
    }
    .page-banner .container h1 {
        font-size: 52px;
    }
}
@media only screen and (max-width: 1365px) {
    .home-banner {
        .home-banner--bottom .home-banner--bottom-box {
            .box-text {
                width: 100%;
                padding-left: 25px;
                padding-right: 50px;
            }
            .box-image {
                display: none;
            }
        }
    }
    .block--image-text-overflow {
        .image-text-overflow {
            .box-overflow {
                max-width: 600px;
                padding: 50px;
            }
        }
    }
}
@media only screen and (max-width: 1250px) {
    .common-title--md {
        font-size: 32px;
    }
    header.header {
        height: 120px;
        &.nav-up {
            top: -120px;
        }
    }
    .header.header .brand img {
        width: 250px;
    }
    header.header .nav-primary ul li {
        font-size: 18px;
    }
    .block--home-info .col-lg-9 {
        .common-title--lg.desktop {
            left: 63%;
            font-size: 32px;
            max-width: 350px;
        }
        .image-text-overflow {
            max-width: 62%;
            position: static;
            .box-overflow {
                max-width: 38%;
            }
        }
    }
    .block--home-info .widget-col .home-info--right .common-title--md {
        max-width: 350px;
    }
    .item-post .item-post--title {
        font-size: 28px;
    }
    .block--info-icons .common-title--md {
        max-width: 400px;
    }
    .footer-newsletter .newsletter-column p {
        font-size: 26px;
    }
    .block--image-text-overflow {
        .image-text-overflow {
            .box-overflow {
                max-width: 550px;
            }
        }
    }
    .block--two-columns-image .text-column .gray-box {
        padding: 50px;
    }
    .footer-newsletter {
        height: 130px;
        p {
            font-size: 22px;
            margin-bottom: 0;
        }
    }
}
@media only screen and (max-width: 1100px) {
    header.header {
        height: 100px;
        &.nav-up {
            top: -100px;
        }
    }
    .header.header {
        .nav-primary {
            width: calc(100% - 300px);
        }
    }
    .quote-wrap .single-quote {
        width: 70%;
    }
    .block--events {
        padding: 50px 0;
        .container {
            .common-title--lg {
                margin-bottom: 40px;
            }
        }
        .common-link {
            margin-top: 25px;
        }
    }
    .item-post {
        .item-post--title {
            font-size: 24px;
            margin: 35px 0 3% 0;
        }
        .item-post--content {
            .moretag {
                margin-top: 30px;
                font-size: 16px;
            }
        }
    }
    .block--two-columns {
        .common-title--lg {
            max-width: 400px;
        }
    }
    .footer-newsletter {
        height: 100px;
        .newsletter-column {
            form input {
                font-size: 16px;
                padding: 0 15px;
            }
            p {
                font-size: 22px;
            }
            .button-common {
                padding: 8px 15px;
            }
        }
    }
    .footer {
        padding: 50px 0;
        .footer-logo {
            width: 85px;
        }
        .container .column {
            .footer-links {
                margin-top: 35px;
            }
            .footer-title {
                font-size: 16px;
            }
            &.third-col {
                padding-left: 0;
            }
        }
        p,
        a {
            font-size: 14px;
        }
        li {
            font-size: 14px;
        }
    }

    .button-common,
    .image-text-overflow .box-overflow--long a {
        font-size: 16px;
    }
    .block--image-text-overflow {
        .image-text-overflow {
            .box-overflow {
                max-width: 500px;
            }
        }
    }
    .block--widget {
        .widget-left {
            max-width: 480px;
        }
        .widget-right {
            min-width: 100%;
            margin-top: 60px;
            padding-left: 15px;
            .widget-item .widget-item--content {
                .content-description {
                    font-size: 16px;
                }
            }
            .widget-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: center;
            }
            .widget-item {
                max-width: 380px;
            }
        }
    }
    .block--post-list {
        .widget-left {
            max-width: 100%;
        }
    }
}
@media only screen and (max-width: 991px) {
    .container-md {
        padding: 0 35px;
    }
    .slider-buttons {
        left: 35px;
    }
    .quote-wrap .single-quote {
        width: 80%;
    }
    .container-sm {
        padding: 0 20px;
    }
    header.header {
        height: 75px;
        background-color: $brand-yellow;
        &.nav-up {
            top: -75px;
        }
        .nav-primary {
            .nav {
                z-index: 10;
                width: 320px;
                display: none;
                right: 0;
                bottom: -75px;
                height: 200px;
                li {
                    display: block;
                }
            }
            ul li {
                margin-right: 20px;
            }
            ul li:last-child {
                margin-right: 0;
            }
        }
        .nav-secondary {
            bottom: 20px;
            top: auto;
            ul {
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }
        .mobile-menu {
            display: block;
        }
    }
    .header.header {
        .brand img {
            width: 200px;
        }
    }
    .mobile-nav {
        display: block;
    }
    header.header {
        .brand {
            bottom: 22px;
            left: 35px;
        }
        .nav-secondary {
            bottom: 10px;
            right: 20px;
            top: auto;
            display: none;
            ul {
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }
    }
    header.header .mobile-menu {
        top: 50%;
        transform: translateY(-50%);
        right: 35px;
    }
    .mobile-nav .nav {
        display: block;
        padding: 35px;
    }
    .home-banner {
        .home-banner--text {
            top: 40%;
            padding-left: 35px;
            span {
                font-size: 46px;
                max-width: 480px;
            }
            .banner-button {
                padding: 12px 20px;
                font-size: 20px;
            }
            .banner-button--video {
                font-size: 26px;
                img {
                    width: 55px;
                }
            }
        }
        .home-banner--bottom {
            bottom: -450px;
            .home-banner--bottom-box {
                width: 100%;
                height: 150px;
                position: relative;
                a {
                    &::before,
                    &::after {
                        background-color: transparent;
                    }
                }
                .box-text {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0 35px;
                    p,
                    .box-text--title {
                        padding-right: 30px;
                    }
                }
                &:nth-child(2) {
                    background-color: $brand-yellow;
                    .box-arrow polygon {
                        fill: $brand-white;
                    }
                }
            }
        }
    }
    .home .wrap .block--two-columns {
        margin-top: 450px;
    }
    .page-banner .container h1 {
        font-size: 46px;
    }
    .block--home-info {
        margin-top: 450px;
        padding-top: 0;
        padding-bottom: 15px;
        .common-title--lg.desktop {
            display: none;
        }
        .common-title--lg.mobile {
            display: block;
        }
        .image-col {
            padding: 0;
            .image-text-overflow {
                max-width: 100%;
                .box-overflow {
                    max-width: 100%;
                    position: static;
                    padding: 35px;
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
        .widget-col {
            padding: 0 35px;
            margin-top: 70px;
            .home-info--right {
                p {
                    width: 45%;
                }
                .common-title--md {
                    max-width: 300px;
                }
            }
        }
    }
    .block--two-columns {
        padding: 100px 0;
        .common-title--lg {
            margin: 0;
            padding: 0;
        }
    }
    .common-title--lg {
        font-size: 38px;
        max-width: 450px;
        padding-left: 35px;
        margin: 50px 0;
    }
    .common-title--md {
        font-size: 28px;
    }
    .block--home-quotes .quote-wrap {
        margin: 0;
    }
    .block--location {
        background-color: $brand-white;
        .container-sm {
            .location-text {
                .common-title--lg {
                    padding: 0;
                    margin: 0;
                }
                .location-info {
                    display: flex;
                    max-width: 450px;
                }
                .location-content p {
                    margin-bottom: 40px;
                }
            }
        }
        .col-lg-8 {
            padding: 0;
            min-width: calc(100% + 70px);
            margin-left: -35px;
            .google-map {
                min-height: 350px;
            }
        }
    }
    .block--info-icons {
        .single-info-icon {
            max-width: 44.5%;
        }
        .common-title--md {
            max-width: 350px;
        }
    }
    .footer-newsletter {
        form input {
            width: 170px;
        }
        p {
            font-size: 18px;
        }
    }
    .footer {
        .footer-logo {
            width: 85px;
        }
        .container .column {
            &.third-col,
            &.last-col {
                padding-top: 50px;
            }
        }
    }
    .block--image-text-overflow {
        margin-top: 0;
        .common-title--lg {
            margin-left: 0;
            margin-top: 0;
            padding-left: 0;
        }
        .image-text-overflow {
            max-width: calc(100% + 70px);
            margin-left: -35px;
            margin-right: -35px;
            .box-overflow {
                max-width: 100%;
                position: relative;
                left: 0;
                top: 0;
                padding: 35px;
            }
        }
    }
    .block--widget {
        .common-title--lg {
            padding-left: 0;
            margin-top: 0;
        }
    }
    .block--widget .widget-right .widget-item {
        min-width: 100%;
    }
    .block--three-columns {
        padding-top: 60px;
        .common-title--lg {
            padding-left: 0;
            margin-top: 0;
        }
    }
    .block--post-list {
        padding: 100px 0;
    }
    .block--two-columns-image .text-column {
        .gray-box {
            padding: 35px;
        }
        .common-title--lg {
            padding-left: 0;
        }
    }
    .gravity-form {
        width: 710px;
        .input-gravity {
            max-width: 350px;
        }
        .textarea-gravity {
            max-width: 350px;
        }
    }
}
@media only screen and (max-width: 767px) {
    .container-md {
        padding: 0 20px;
    }
    .padding-lmnone,
    .block--widget .padding-lmnone {
        padding-left: 0;
    }
    .container-sm {
        .col-12 {
            padding: 0;
        }
    }
    .common-title--lg::after {
        width: 100px;
        margin-top: 10px;
        height: 7px;
    }
    .solid-yellow {
        width: 210px;
    }
    header.header {
        .brand {
            left: 20px;
        }
    }
    header.header .mobile-menu {
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
    .mobile-nav .nav {
        display: block;
        padding: 35px 20px;
    }
    .home-banner {
        .home-banner--bottom .home-banner--bottom-box .box-text {
            padding: 0 20px;
        }
        .home-banner--text {
            padding-left: 20px;
            span {
                font-size: 42px;
                max-width: 440px;
            }
        }
    }
    .page-banner {
        height: 50vh;
        .container h1 {
            font-size: 36px;
        }
    }
    .common-title--lg {
        padding-left: 20px;
        margin: 40px 0 30px 0;
        font-size: 32px;
        max-width: 370px;
    }
    .block--home-info {
        .image-col .image-text-overflow .box-overflow {
            padding: 35px 20px;
            .button-common {
                margin-top: 15px;
            }
        }
        .widget-col {
            padding: 0 20px;
            margin-top: 30px;
            .home-info--right {
                .common-title--md {
                    &::after,
                    &::before {
                        content: "";
                    }
                }
                img {
                    float: none;
                    width: calc(100% + 40px);
                    margin-left: -20px;
                }
                p {
                    width: 100%;
                    margin: 25px 0;
                }
            }
        }
    }
    .slider-buttons {
        left: 20px;
    }
    .block--home-quotes {
        .quote-wrap .single-quote {
            width: 90%;
        }
    }
    .quote-wrap {
        height: 475px;
        .single-quote {
            &.active {
                left: 0;
            }
            p {
                font-size: 18px;
            }
            .quote-author span {
                font-size: 18px;
            }
        }
    }
    .block--events {
        h1 {
            padding-left: 0;
        }
        .container .common-title--lg {
            margin: 0 0 30px 0;
            text-align: left;
            &::after {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .col-md-6 {
            padding: 0;
        }
        .col-md-6:last-child {
            display: none;
        }
        .item-post {
            .item-post--text-wrap {
                padding: 0;
            }
        }
        .common-link {
            margin-left: 0;
        }
    }
    .block--two-columns {
        padding: 60px 0;
        .image-column {
            padding: 0;
            padding-bottom: 30px;
        }
        .text-column {
            padding: 0;
        }
    }
    .block--two-columns-image {
        .col-md-6:first-child {
            padding: 20px;
        }.col-md-6:last-child {
            padding: 5px;
        }
         .text-column .common-title--lg {
            padding-left: 15px;
        }
    }
    .block--info-icons {
        padding: 60px 0;
        .single-info-icon--wrap {
            padding-left: 0;
        }
        .single-info-icon {
            max-width: 80%;
        }
    }
    .block--location {
        .container-sm {
            .location-text {
                padding: 40px 40px 40px 0;
            }
        }
        .col-lg-8 {
            padding: 0;
            min-width: calc(100% + 40px);
            margin-left: -20px;
            .google-map {
                /*min-height: 450px;*/
            }
        }
    }
    .footer-newsletter {
        position: relative;
        height: 120px;
        .mobile-newsletter {
            display: block;
            position: absolute;
            top: 15px;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            font-size: 20px;
            width: 100%;
            text-align: center;
        }
        form {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 60px;
            min-width: 280px;
        }
        .desktop-newsletter {
            display: none;
        }
        .pfb-signup-button {
            top: 84px;
            left: calc(50% + 40px);
        }
        .col-sm-6:first-child {
            display: none;
        }
        .col-sm-6:last-child {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    .footer .container {
        padding: 0 20px;
    }
    .block--image-text-overflow {
        .image-text-overflow {
            max-width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
            .box-overflow .button-common {
                margin-top: 15px;
            }
        }
    }
    .block--widget {
        h1,
        .padding-b {
            margin-bottom: 30px;
        }
    }
    .block--three-columns {
        padding: 60px 0;
        .common-title--lg {
            max-width: 200px;
        }
        .third-column {
            h5,
            h6 {
                margin-top: 40px;
            }
        }
    }
    .block--post-list {
        padding: 60px 0;
        .item-post {
            margin-bottom: 60px;
        }
    }
    .block--section {
        padding: 60px 0;
    }
    .block--two-columns-image {
        .image-column {
            padding: 0;
            max-width: 450px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .text-column {
            padding: 0;
            .gray-box {
                width: calc(100% + 40px);
                margin-left: -20px;
            }
        }
    }
    .block--steps {
        .col-12 {
            padding: 0;
        }
        .common-title--lg {
            margin-bottom: 0;
            padding-left: 0;
        }
        .single-step {
            display: flex;
            flex-wrap: wrap;
            width: 275px;
            margin: 0 auto;
            .num-box {
                order: 2;
                margin: 0;
                padding: 0;
                background-color: transparent;
                height: auto;
                margin-bottom: 30px;
                .num-box--text {

                }
                .num-box--num {
                    display: none;
                }
            }
            .title-box {
                order: 1;
                position: static;
                padding-left: 70px;
                width: 265px;
                min-height: 85px;
                margin-bottom: 25px;
                span {
                    width: 210px;
                }
                .title-box--num {
                    position: absolute;
                    left: 0;
                    top: 55px;
                    display: inline-block;
                    font-size: 96px;
                }
            }
            .arrow-down {
                bottom: -20px;
            }
            .hidden-step {
                order: 3;
            }
        }
    }
    .o-nas .block--info-icons .single-info-icon {
        margin: 0;
    }
    .block--image-text-overflow .image-text-overflow .box-overflow {
        padding: 35px 20px;
    }
    .gravity-form {
        width: 100%;
        .input-gravity {
            max-width: 100%;
            margin: 0 0 35px 0 !important;
            padding-right: 0 !important;
            input {
                width: 100% !important;
            }
        }
        .textarea-gravity {
            max-width: 100%;
            position: relative;
            padding-right: 0 !important;
            .ginput_container_textarea {
                textarea {
                    height: 200px;
                }
            }
        }
        .gform_button {
            width: auto !important;
            line-height: 1 !important;
        }
    }
    .block--form .single-info-icon--wrap {
        width: 100%;
    }
}
@media only screen and (max-width: 575px) {
    .home-banner {
        .home-banner--text span {
            font-size: 38px;
            max-width: 400px;
        }
    }
    .footer {
        .container {
            .column {
                padding-right: 0;
                &.first-col {
                    p {
                        display: none;
                    }
                }
                &.second-col,
                &.third-col .footer-title {
                    display: none;
                }
                &.third-col {
                    padding-top: 0;
                    ul {
                        margin-top: 0;
                    }
                }
                &.last-col {
                    padding-left: 0;
                    padding-top: 0;
                    .footer-contacts {
                        margin-top: 0;
                        li:first-child {
                            float: left;
                            width: 45%;
                        }
                        li:nth-child(n + 2) {
                            float: right;
                            width: 50%;
                            min-width: 160px;
                        }
                    }
                    .footer-title {
                        display: none;
                    }
                }
                .footer-contacts li:last-child {
                    margin-top: 10px;
                }
            }
        }
    }
    .sub-footer p {
        padding: 0 20px;
    }
}
@media only screen and (max-width: 480px) {
    .common-title--lg::after {
        width: 90px;
        height: 5px;
    }
    .header.header {
        .nav-primary {
            width: calc(100% - 255px);
        }
    }
    .home-banner {
        .home-banner--text {
            top: 40%;
            padding-left: 10%;
            span {
                font-size: 29px;
                max-width: 300px;
                margin-bottom: 25px;
            }
            .banner-button {
                padding: 10px 16px;
                font-size: 18px;
            }
        }
    }
    .page-banner {
        height: 50vh;
        .container h1 {
            font-size: 32px;
        }
    }
    .block--home-info {
        .image-col .image-text-overflow .box-overflow {
            padding: 25px 20px;
        }
        .widget-col .home-info--right .common-title--md {
            max-width: 280px;
        }
    }
    .block--home-quotes {
        .common-title--lg {
            font-size: 26px;
        }
        .quote-wrap {
            padding: 35px 0;
            height: 380px;
            .single-quote {
                width: 100%;
                top: 80px;
                p {
                    font-size: 16px;
                }
                .quote-author {
                    margin-top: 25px;
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .item-post .item-post--title {
        font-size: 22px;
    }
    .block--widget {
        .common-title--lg {
            max-width: 300px;
        }
    }
    .common-title--lg{
        font-size: 26px;
        max-width: 310px;
    }
    .common-title--md {
        font-size: 24px;
    }
    .block--location {
        .container-sm {
            .location-text {
                padding: 40px 40px 40px 0;
            }
        }
    }
    .block--two-columns {
        padding: 40px 0;
        .common-title--lg {
            max-width: 270px;
            margin-bottom: 30px;
        }
        .button-common {
            margin-top: 15px;
        }
    }
    .block--info-icons {
        padding: 40px 0;
        .single-info-icon {
            max-width: 100%;
            margin-right: 0;
        }
        .common-title--md {
            max-width: 300px;
        }
        .button-white {
            margin-left: 0;
        }
        .button-yellow {
            margin-right: 15px;
            margin-bottom: 25px;
        }
    }
    .block--location {
        .container-sm {
            .location-text {
                .common-title--lg {
                    max-width: 180px;
                }
                .location-info {
                    display: block;
                }
            }
        }
    }
    .footer-newsletter {
        .mobile-newsletter {
            font-size: 18px;
            min-width: 300px;
        }
    }
    .footer .container .column .footer-contacts {
        li {
            padding-left: 25px;
        }
        .contact-logo {
            height: 15px;
        }
        .contact-logo--arrow {
            height: 20px;
        }
    }
    .block--widget {
        .widget-right {
            margin-top: 35px;
        }
        .widget-item {
            .widget-item--date {
                width: 90px;
                height: 90px;
                .date-day {
                    font-size: 48px;
                }
                .date-month {
                    font-size: 20px;
                }
            }
            .widget-item--content {
                padding-left: 110px;
            }
        }
    }
    .block--three-columns {
        padding-top: 40px;
        .common-title--lg {
            max-width: 160px;
        }
        .col-lg-4 {
            padding-right: 0;
        }
    }
    .block--post-list {
        padding: 40px 0;
        .item-post {
            margin-bottom: 40px;
        }
    }
    .block--section {
        padding: 40px 0;
    }
    .home-banner {
        .home-banner--bottom {
            bottom: -540px;
            .home-banner--bottom-box {
                width: 100%;
                height: 180px;
                position: relative;
                .box-text {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-top: 0;
                }
            }
        }
    }
    .home-banner {
        .home-banner--bottom {
            bottom: -540px;
            .home-banner--bottom-box {
                width: 100%;
                height: 180px;
                position: relative;
                .box-text {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-top: 0;
                }
            }
        }
    }
    .home .wrap .block--two-columns {
        margin-top: 540px;
    }
}
@media only screen and (max-width: 379px) {
    .common-title--lg,
    .block--home-quotes .common-title--lg,
    .common-title--md {
        font-size: 22px;
        max-width: 270px;
    }
    .block--home-info .widget-col .home-info--right .common-title--md {
        max-width: 250px;
    }
    .block--home-quotes .quote-wrap {
        height: 400px;
    }
    .block--widget {
        .common-title--lg {
            max-width: 270px;
        }
    }
    .home-banner {
        .home-banner--text {
            span {
                font-size: 25px;
                max-width: 270px;
            }
        }
    }
    .block--location {
        .container-sm {
            .location-text {
                .common-title--lg {
                    max-width: 130px;
                }
            }
        }
    }
    .block--two-columns {
        .common-title--lg {
            max-width: 230px;
        }
    }
    .footer-newsletter {
        .mobile-newsletter {
            top: 20px;
            font-size: 16px;
            min-width: 310px;
        }
        .newsletter-column form {
            min-width: 260px;
            input {
                width: 150px;
            }
        }
    }
    .footer {
        .container {
            .column {
                &.last-col {
                    .footer-contacts {
                        li:first-child {
                            max-width: 120px;
                        }
                        li:nth-child(n + 2) {
                            margin-right: -10px;
                        }
                    }
                }
            }
        }
    }
}
